<template>
  <Articles
    :layout="layout"
    :articles="articles"
    :slider-headline="headline"
    :headline-level="headlineLevel"
  />
</template>

<script>
  import {
    AlchemyElement,
    getElementsIngredient,
    getElementsValue,
  } from "~/mixins/alchemyVue"

  export default {
    name: 'AlchemyArticles',
    mixins: [AlchemyElement],
    computed: {
      articles() {
        return this.element.nestedElements.map((article) => {
          return {
            id: article.id,
            position: article.position,
            title: getElementsValue(article, "title"),
            copy: getElementsValue(article, "copy"),
            image: getElementsValue(article, "image"),
            srcset: getElementsIngredient(article, "image").srcset,
            imageAltText: getElementsIngredient(article, "image").altText,
            link: getElementsValue(article, "link"),
          }
        })
      },
      layout() {
        return this.getValue("layout")
      },
      headline() {
        return this.getValue("headline")
      },
      headlineLevel() {
        return this.getIngredient("headline")?.level || 3
      },
    },
  }
</script>
