<template>
  <Container data-test-name="articles-list">
    <div
      v-for="article in articles"
      :key="article.id"
      class="article-list-item-wrap"
    >
      <NuxtLink :to="article.link" class="article-list-item-link">
        <img
          class="article-list-item-link-image"
          :src="article.image"
          :alt="article.imageAltText"
          loading="lazy"
        />
      </NuxtLink>
      <div class="article-list-item-info">
        <span class="article-list-item-title">
          {{ article.title }}
        </span>
        <span class="article-list-item-description">
          {{ article.copy }}
        </span>
        <Button
          :to="article.link"
          class="article-list-item-button"
          size="small"
          aria-label="Go to article page"
        >
          Read now
        </Button>
      </div>
    </div>
  </Container>
</template>

<script>
  import Container from "~/components/Container"
  import Button from "~/components/Button"

  export default {
    components: { Container, Button },
    props: {
      articles: {
        type: Array,
        default: () => [],
      },
      sliderHeadline: {
        type: String,
        default: "Articles Slider",
      },
      headlineLevel: {
        type: Number,
        default: 3,
      },
    },
  }
</script>

<style lang="scss" scoped>

  .article-list-item-wrap {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;

    grid-gap: $space-m;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-m;

    @include viewport("mini") {
      grid-gap: $space-s;
      flex-direction: column;
    }
  }

  .article-list-item-link {
    text-decoration: none;
    color: $onyx;
    max-width: $space-l * 4;

    @include viewport("sm") {
      max-width: $space-l * 3;
    }

    @include viewport("mini") {
      width: 100%;
      min-height: 1px;
      max-height: $space-l * 3;
      max-width: none;

      display: flex;
      align-items: stretch;
    }

    .article-list-item-link-image {
      width: 100%;
      box-shadow: $shadow;
      object-fit: cover;
      border-radius: 4px;
      transition: box-shadow 120ms ease-in-out, transform 120ms ease-in-out;
      @include viewport("mini") {
        height: initial;
        width: 100%;
      }
    }
  }

  .article-list-item-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    @include viewport("mini") {
      width: 100%;
      margin-bottom: $base-spacing * 3;
      margin-right: 0px;
    }
  }

  .article-list-item-title {
    @include type('h4');
  }
  .article-list-item-description {
    color: $gray-text;
    margin-bottom: $space-s;
  }
</style>
